import React from 'react';
import ReactMarkdown from 'react-markdown';
import { useSelector } from 'react-redux';
import { graphql, navigate, Link } from 'gatsby';
import { MathJaxContext, MathJax } from 'better-react-mathjax';
import { useMediaQuery } from 'usehooks-ts';
import Clouds from '../assets/images/clouds.svg';
import FolderIcon from '../assets/icons/folder.svg';
import { Layout, SEO } from '../components';
import VideoPlayer from '../components/VideoPlayer';

const Module = ({
  data: {
    datoCmsModule: {
      description,
      image,
      subCategory,
      seo,
      slug,
      title,
      seoKeywords,
      description2,
      schemaMarkup,
      subscriptionforumbuttons,
      video,
      videos,
      youtubeVideo,
    },
  },
}) => {
  const screen479 = useMediaQuery('(max-width: 479px)');

  const user = useSelector((state) => state.user.data);
  const isSubscribed = user?.subscribed ?? false;

  const showSubscriptionButton = subscriptionforumbuttons?.[0]?.displaysubscription ?? false;
  const showForumButton = subscriptionforumbuttons?.[0]?.displayforum ?? false;
  const subscriptionBackgroundColor =
    subscriptionforumbuttons?.[0]?.subbackgroundcolor?.hex ?? '#23D4FF';
  const subscriptionTextColor = subscriptionforumbuttons?.[0]?.subtextcolor?.hex ?? '#000000';
  const forumBackgroundColor =
    subscriptionforumbuttons?.[0]?.forumbackgroundcolor?.hex ?? '#FD99C4';
  const forumTextColor = subscriptionforumbuttons?.[0]?.forumtextcolor?.hex ?? '#000000';

  const allVideos = [
    ...(video?.video?.streamingUrl ? [video?.video?.streamingUrl] : []),
    ...(youtubeVideo ? youtubeVideo.split(',') : []),
    ...(videos?.length ? videos.map((vid) => vid.video.streamingUrl) : []),
  ];

  const renderActionButtons = () => {
    if (!showSubscriptionButton && !showForumButton) return;
    return (
      <div className="action-container">
        {showSubscriptionButton && !isSubscribed && (
          <button
            onClick={() => navigate('/subscriptions')}
            className="w-nav-brand sign-in-badge forum-sub-button"
            style={{
              backgroundColor: subscriptionBackgroundColor,
              color: subscriptionTextColor,
            }}
          >
            <span class="2">Join Now</span>
          </button>
        )}
        {showForumButton && (
          <button
            onClick={() => navigate('/forum')}
            className="w-nav-brand sign-in-badge forum-sub-button"
            style={{
              backgroundColor: forumBackgroundColor,
              color: forumTextColor,
            }}
          >
            <span class="2">Forum</span>
          </button>
        )}
      </div>
    );
  };

  return (
    <MathJaxContext>
      <Layout>
        <SEO
          description={seo.description}
          image={seo.image}
          title={seo.title}
          seoKeywords={seoKeywords}
          schema={schemaMarkup && JSON.parse(schemaMarkup)}
        />
        <div className="header">
          <div className="container">
            {screen479 && image?.url && <img src={image.url} alt={image.alt} />}
            <div className="grid main-content">
              <div className="content-block" style={{ minHeight: screen479 ? 'unset' : '630px' }}>
                {!screen479 && image?.url && (
                  <img src={image.url} alt={image.alt} style={{ float: 'right', width: '60%' }} />
                )}
                {renderActionButtons()}
                <h1 className="content-h2">{title}</h1>
                {allVideos.length
                  ? allVideos.map((src) => (
                      <VideoPlayer className="video-player" isSmall src={src} />
                    ))
                  : null}
                <MathJax hideUntilTypeset="first">
                  {description && <ReactMarkdown className="paragraph" children={description} />}
                  {!description && description2 && (
                    <div dangerouslySetInnerHTML={{ __html: description2 }} />
                  )}
                </MathJax>
                <div className="w-layout-grid grid">
                  {subCategory &&
                    subCategory.map((category) => (
                      <div className="feature-wrap" key={category.id}>
                        <img src={FolderIcon} alt="Folder" className="feature-icon" />
                        <div className="feature-content-wrap">
                          <Link to={`/modules/${slug}/${category.slug}`} className="link">
                            {category.name}
                          </Link>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              {/* {image?.url && <img src={image.url} alt={image.alt} />} */}
            </div>
          </div>
          <img src={Clouds} alt="Clouds" className="pattern-left" />
        </div>
      </Layout>
    </MathJaxContext>
  );
};

export default Module;

const query = graphql`
  query ModuleQuery($slug: String!) {
    datoCmsModule(slug: { eq: $slug }) {
      slug
      comingSoon
      description
      description2
      id
      video {
        url
        video {
          streamingUrl
        }
      }
      videos {
        video {
          streamingUrl
        }
      }
      youtubeVideo
      image {
        alt
        url
      }
      subCategory {
        id
        name
        slug
      }
      position
      title
      seo {
        description
        title
        image {
          url
        }
      }
      seoKeywords {
        id
        keyword
      }
      schemaMarkup
      subscriptionforumbuttons {
        displaysubscription
        displayforum
        subbackgroundcolor {
          hex
        }
        subtextcolor {
          hex
        }
        forumbackgroundcolor {
          hex
        }
        forumtextcolor {
          hex
        }
      }
    }
  }
`;
export { query };
